body {
	padding: 0;
	margin: 0;
	font-family: monospace;
}

a {
	color: rgb(0, 255, 0);
}

.curtain {
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

.left-panel, .right-panel {
	width: 15%;
	height: 100vh;
	float: left;
	z-index: 2;
	position: relative;
	background-color: white;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	transition: all 1s ease-in;
}

.right-panel {
	float: right;
}

.right-panel > div {
	display: flex;
	flex-direction: column;
}

.text-center {
	text-align: center;
}

.right-panel > div > a {
	margin: 10px 0;
	color: black;
	text-decoration: none;
}

.left-panel > div {
	margin: 10px 0 0;
}

.page-title {
	margin: 10px 0 30px;
}

.social {
	width: 15px;
	margin: 0 10px;
}

.content {
	position: absolute;
	left: 15%;
	width: 70%;
	height: 100%;
	background: #000000;
	z-index: 1;
	color: #15B04B;
	font-family: monospace;
	overflow-y: scroll;
}

.intro {
	height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: 0 0;
}

.hide {
	background-color: rgba(255,255,255, 0.1);
	padding: 10px
}

.intro .section-header {
	color: black;
	font-weight: 900;
	font-size: 16px;
	width: 50%;
	height: 100%;
	padding: 20px;
	justify-content: flex-end;
	display: flex;
}

.input {
	background-color: inherit;
	border: 0;
	color: #15B04B;
	overflow-x: wrap;
}

.social {
	color: #15B04B;
}

.text-primary {
	color: #15B04B;
}

.text-red {
	color: #ff0000;
}

.text-alt {
	color: #15B04B55;
}

.text-info {
	color: indigo;
}

.text-bold {
	font-weight: bold;
}

.text-light {
	font-weight: lighter;
}

.btn-geek{
	width: 80px;
	height: 30px;
	border: 2px solid #15B04B;
	background-color: inherit;
	color: #15B04B;
}

.btn-ui {
	width: 80px;
	height: 30px;
	border: 2px solid white;
	border-radius: 5px;
	background-color: inherit;
	color: white;
}

* {
	box-sizing: border-box;
}

.timeline {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
}

.timeline::after {
	content: '';
	position: absolute;
	width: 4px;
	background-color: #15B04B;
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -2px;
}

.container {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
}

.container::after {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	right: -14px;
	background-color: #15B04B;
	border: 4px solid black;
	top: 15px;
	border-radius: 50%;
	z-index: 1;
}

.left {
	left: 0;
}

.right {
	left: 50%;
}

.right::after {
	left: -14px;
}

.timeline-card {
	padding: 10px 30px;
	background-color: inherit;
	border: 2px dashed #15B04B;
	position: relative;
	border-radius: 6px;
}

.section {
	margin-bottom: 50px;
	padding: 20px;
}

.section-header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-section {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.window {
	margin: 30px;
	z-index: 1;
	box-shadow: 2px red;
	border-radius: 5px;
	border: 2px solid #2f323c;
	width: 600px;
}

.window-title-bar {
	background-color: #2f323c;
	padding: 10px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 3px 3px 0 0 ;
}

.window-title > h3 {
	padding: 0;
	margin: 0;
}

.window-body {
	overflow-y: scroll;
	height: 400px;
	padding: 5px;
}

.window-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 5px;
}

.footer-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 100px;
}

.footer > a {
	color: black;
}

@media screen and (max-width: 400px) {
	.intro .section-header {
		font-size: 13px;
		width: 75%;
		justify-content: flex-end;
	}
}

@media screen and (max-width: 600px) {
	.timeline::after {
		left: 31px;
	}

	.container {
		width: 100%;
		padding-left: 70px;
		padding-right: 25px;
	}

	.left::after, .right::after {
		left: 15px;
	}

	.right {
		left: 0;
	}

	.right-panel {
		display: none;
	}

	.curtain {
		display: flex;
		flex-direction: column;
		height: auto;
		overflow-y: all;
	}

	.left-panel {
		height: 100vh;
		position: relative;
		width: 100%;
		z-index: 0;
	}

	.content {
		position: relative;
		width: 100%;
		left: 0;
		height: auto;
		z-index: 0;
	}

	.window {
		margin: 5px;
		z-index: 1;
		box-shadow: 2px red;
		border-radius: 5px;
		border: 2px solid black;
		width: 100%;
	}

	.footer-section {
		flex-direction: column;
		align-items: center;
		padding: 0 20px;
		text-align: center;
	}

	.footer-section > div{
		margin: 10px 0 10px;
	}
}
